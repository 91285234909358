<template>
  <div id="main-content" class="v-card">
    <v-dialog v-model="dialogAwait" width="500" persistent>
      <v-card>
        <v-overlay :value="dialogAwait" absolute>
          <!-- <v-progress-circular indeterminate size="64"></v-progress-circular> -->
        </v-overlay>
        <v-card-title class="headline grey lighten-2"
          >Ordem de serviço</v-card-title
        >
        <v-card-subtitle>
          <p class="body-1">{{ dialogAwait_message }}</p>
          <v-progress-linear v-model="cont_tabs" height="25" color="green">
            <strong>{{ Math.ceil(cont_tabs) }}%</strong>
          </v-progress-linear>
        </v-card-subtitle>
      </v-card>
    </v-dialog>
    <v-card class="d-flex" style="width: 100%">
      <div class="tableCli" style="max-width: 100%">
        <v-data-table
          :headers="HeadersOS"
          dense
          :items="ItemOS"
          class="elevation-1"
          no-data-text="Sem dados..."
          fixed-header
          :height="altura"
          :loading="loading"
          :search="search"
          :items-per-page="21"
          :footer-props="{
            disableItemsPerPage: true,
          }"
        >
          <template v-slot:top v-if="!user.isCliente">
            <v-row dense class="pa-4">
              <h3>Ordem de Serviço</h3>
            </v-row>
            <v-toolbar flat color="white">
              <v-row dense>
                <v-col cols="1">
                  <v-btn
                    color="indigo"
                    dark
                    @click.stop="abrePainel"
                    class="mt-3"
                  >
                    <v-icon v-if="!stDrawer">mdi-chevron-right</v-icon
                    ><v-icon v-if="stDrawer">mdi-chevron-left</v-icon>Painéis
                  </v-btn>
                </v-col>

                <v-col cols="1">
                  <v-btn
                    class="mx-5 mt-2"
                    fab
                    x-small
                    dark
                    color="indigo"
                    @click="novo()"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="3" v-if="NrRegFiltro !== null">
                  <span style="font-size: 0.7rem"
                    >Filtro aplicado no <strong>{{ filtroEscolhido }}</strong
                    >, {{ NrRegFiltro }} registro(s) encontrado(s).</span
                  >
                  <v-btn
                    class="mx-5 mt-2"
                    x-small
                    dark
                    fab
                    color="error"
                    @click="LimpaFiltro()"
                  >
                    <v-icon dark class="ml-1">mdi-eraser</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <v-select
                    v-model="filtroEscolhido"
                    :items="itemsFiltro"
                    label="Escolha o campo"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    placeholder="digite aqui..."
                    v-model="stringFiltro"
                    :disabled="!filtroEscolhido"
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="mx-5 mt-2"
                    x-small
                    dark
                    color="indigo"
                    @click="filtraRegistro(filtroEscolhido, stringFiltro)"
                  >
                    <v-icon dark class="ml-1">mdi-account-search</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <!-- <template v-slot:top>
          <v-toolbar flat color="white">
            <v-btn class="mx-5" fab small dark color="indigo" @click="novo()">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template> -->

          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon small class="mr-2" @click="tracker(item)">mdi-truck</v-icon>
          <v-icon small @click="verDados(item)">mdi-eye</v-icon> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="tracker(item)"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-truck</v-icon
                >
              </template>
              <span>Tracker</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small @click="verDados(item)" v-bind="attrs" v-on="on"
                  >mdi-eye</v-icon
                >
              </template>
              <span>Visualizar cadastro</span>
            </v-tooltip>
            <v-icon small @click="imprimirFormAbertura(item)"
              >mdi-printer</v-icon
            >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="handleVincularAgenda(item)"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    item.ZF1_CODCLI.substring(0, 6).trim() === '' ||
                      item.ZF1_CODTEC.substring(0, 6).trim() === ''
                  "
                  >mdi-calendar-search</v-icon
                >
              </template>
              <span>Vincular Agenda</span>
            </v-tooltip>
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="handleVincularNovaAgenda(item)"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-calendar-plus</v-icon
                >
              </template>
              <span>Incluir Agenda</span>
            </v-tooltip> -->
          </template>
        </v-data-table>
      </div>

      <div class="dadosCli text-center">
        <v-dialog v-model="DialogDados" width="90%" persistent>
          <v-card v-if="boilerplate" ref="form">
            <v-card-title class="headline grey lighten-2"
              >{{ titleDialog }} <v-spacer></v-spacer>
              <small style="font-variant-caps: all-small-caps">{{
                valorCliente
              }}</small></v-card-title
            >

            <v-card-text>
              <v-row dense class="mt-5" v-if="!isTracker">
                <v-col
                  v-for="campo in camposTela"
                  :key="campo.ZZP_CAMPO"
                  :cols="campo.ZZP_COLS"
                >
                  <money
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :label="campo.ZZP_TITULO"
                    v-if="
                      campo.ZZP_TIPO === 'N' &&
                        !isTracker &&
                        (!isMolde || (isMolde && campo.isMolde))
                    "
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    dense
                    v-bind="money"
                    :readonly="isReading || campo.ZZP_VISUAL == 1"
                    class="
                      v-input
                      text-uppercase
                      v-input--dense
                      theme--light
                      v-text-field v-text-field--is-booted
                    "
                  ></money>
                  <!--campo de texto-->
                  <v-text-field
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :label="campo.ZZP_TITULO"
                    v-if="
                      campo.ZZP_TIPO === 'T' &&
                        !isTracker &&
                        (!isMolde || (isMolde && campo.isMolde))
                    "
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    @blur="preencheDadosBaseInstalada(campo)"
                    class="text-uppercase"
                    dense
                    :readonly="isReading || campo.ZZP_VISUAL == 1"
                  ></v-text-field>
                  <!--campo de data-->
                  <v-text-field
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :label="campo.ZZP_TITULO"
                    v-if="
                      campo.ZZP_TIPO === 'D' &&
                        !isTracker &&
                        (!isMolde || (isMolde && campo.isMolde))
                    "
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    v-mask="'##/##/####'"
                    class="text-uppercase"
                    dense
                    :readonly="isReading || campo.ZZP_VISUAL == 1"
                  ></v-text-field>
                  <v-select
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :items="campo.ITEMS"
                    :label="campo.ZZP_TITULO"
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    v-if="
                      campo.ZZP_TIPO === 'S' &&
                        !isTracker &&
                        (!isMolde || (isMolde && campo.isMolde))
                    "
                    class="text-uppercase"
                    dense
                    :readonly="isReading || campo.ZZP_VISUAL == 1"
                  ></v-select>
                  <v-autocomplete
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :value="dadosTela[campo.ZZP_CAMPO.trim()]"
                    :placeholder="dadosTela[campo.ZZP_CAMPO.trim()]"
                    style="margin-top: -22px"
                    dense
                    :items="itemsTipo"
                    @change="mostraApontamentos(campo)"
                    @focus="carregaItems(campo)"
                    no-data-text="Buscando dados..."
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    filled
                    :label="campo.ZZP_TITULO"
                    background-color="white"
                    v-if="
                      (campo.ZZP_TIPO === 'A' || campo.ZZP_TIPO === 'F') &&
                        !isTracker &&
                        (!isMolde || (isMolde && campo.isMolde))
                    "
                    class="text-uppercase"
                    :readonly="isReading || campo.ZZP_VISUAL == 1"
                  ></v-autocomplete>
                  <v-textarea
                    v-model="dadosTela[campo.ZZP_CAMPO.trim()]"
                    background-color="white"
                    clearable
                    @focus="log()"
                    counter
                    :items="campo.ITEMS"
                    :rules="
                      obrigat(
                        dadosTela[campo.ZZP_CAMPO.trim()],
                        campo.ZZP_OBRIGA,
                      )
                    "
                    :label="campo.ZZP_TITULO"
                    :readonly="isReading || campo.ZZP_VISUAL == 1"
                    v-if="
                      campo.ZZP_TIPO === 'Y' &&
                        !isTracker &&
                        (!isMolde || (isMolde && campo.isMolde))
                    "
                  ></v-textarea>
                  <!--
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="DataFormatadaInicial"
                      :label="campo.ZZP_TITULO"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="DtInicial"
                    no-title
                    scrollable
                    locale="pt-br"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menuDtInicial = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuDtInicial.save(DtInicial)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu> -->
                </v-col>
              </v-row>
              <v-row>
                <v-data-table
                  :headers="headersApontamentos"
                  dense
                  :items="itemsApontamentos"
                  class="elevation-1"
                  no-data-text="Sem dados..."
                  fixed-header
                  hide-default-footer
                  :loading="loading"
                  v-if="isReading || isMolde"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Apontamentos</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <v-dialog v-model="dialog" max-width="1000px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Adicionar item
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <!-- <v-col
                            v-for="(campoApont) in camposApontamentos"
                            :key="campoApont.ZZP_CAMPO"
                            :cols="campoApont.ZZP_COLS"
                          >
                            <v-text-field
                              v-model="editedItem.ZZP_CAMPO"
                              :value="editedItem.ZZP_CAMPO"
                              :label="editedItem.ZZP_TITULO"
                              v-if="campoApont.ZZP_TIPO === 'T'"
                              :rules="obrigat(campoApont.ZZP_CAMPO,campoApont.ZZP_OBRIGA)"
                              class="text-uppercase"
                              dense
                              :readonly="isReading || campoApont.ZZP_VISUAL == 2"
                            ></v-text-field>

                            <v-select
                              v-model="editedItem.campoApont.ZZP_CAMPO"
                              :placeholder="editedItem[campoApont.ZZP_CAMPO.trim()]"
                              :items="campoApont.ITEMS"
                              :label="campoApont.ZZP_TITULO"
                              :rules="obrigat(editedItem[campoApont.ZZP_CAMPO.trim()],campoApont.ZZP_OBRIGA)"
                              v-if="campoApont.ZZP_TIPO === 'S' && !isTracker"
                              class="text-uppercase"
                              dense
                              :readonly="isReading || campoApont.ZZP_VISUAL == 2"
                            ></v-select>
                            <v-autocomplete
                              v-model="editedItem.campoApont.ZZP_CAMPO"
                              :placeholder="editedItem[campoApont.ZZP_CAMPO.trim()]"
                              style="margin-top: -22px"
                              dense
                              :items="itemsTipo"
                              @focus="carregaItems(campoApont)"
                              @click="carregaItems(campoApont)"
                              no-data-text="Buscando dados..."
                              :rules="obrigat(editedItem[campoApont.ZZP_CAMPO.trim()],campoApont.ZZP_OBRIGA)"
                              filled
                              :label="campoApont.ZZP_TITULO"
                              background-color="white"
                              v-if="(campoApont.ZZP_TIPO === 'A' || campoApont.ZZP_TIPO === 'F') && !isTracker"
                              class="text-uppercase"
                              :readonly="isReading || campoApont.ZZP_VISUAL == 2"
                            ></v-autocomplete>
                          </v-col> -->

                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <v-autocomplete
                                    v-model="editedItem.ZF3_CODSER"
                                    :placeholder="'Tipo de serviço'"
                                    style="margin-top: -22px"
                                    dense
                                    :items="itemsServico"
                                    @focus="
                                      carregaItemsApont({
                                        ZZP_CAMPO: 'ZF3_CODSER',
                                        ZZP_F3TAB: 'AA5',
                                        ZZP_F3CAMP: 'AA5_CODSER;AA5_DESCRI',
                                      })
                                    "
                                    filled
                                    :label="'Tipo de serviço'"
                                    background-color="white"
                                    class="text-uppercase"
                                    :readonly="isReading"
                                  ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <v-autocomplete
                                    v-model="editedItem.ZF3_CODPRO"
                                    :placeholder="'Produto'"
                                    style="margin-top: -22px"
                                    dense
                                    :items="itemsApont"
                                    @focus="
                                      carregaItemsApont({
                                        ZZP_CAMPO: 'ZF3_CODPRO',
                                        ZZP_F3TAB: 'SB1',
                                        ZZP_F3CAMP: 'B1_COD;B1_DESC ',
                                      })
                                    "
                                    @change="getPrecoItem()"
                                    filled
                                    :label="'Produto'"
                                    background-color="white"
                                    class="text-uppercase"
                                    :readonly="isReading"
                                  ></v-autocomplete>
                                </v-col>

                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                  style="margin-top: -20px"
                                >
                                  <v-text-field
                                    v-model="editedItem.ZF3_QUANT"
                                    label="Quantidade"
                                    @blur="gatilhoValorTotal()"
                                    @change="handleQuantidade"
                                    dense
                                    :readonly="isReading"
                                  ></v-text-field>
                                </v-col>
                              </v-row>

                              <v-row class="mb-3">
                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                  style="margin-top: -20px"
                                >
                                  <label
                                    style="
                                      font-variant: all-small-caps;
                                      font-size: 85%;
                                    "
                                    >Valor unitário</label
                                  >
                                  <money
                                    v-model="editedItem.ZF3_VUNIT"
                                    dense
                                    v-bind="money"
                                    :readonly="isReading"
                                    class="
                                      v-input
                                      text-uppercase
                                      v-input--dense
                                      theme--light
                                      v-text-field v-text-field--is-booted
                                    "
                                  ></money>
                                </v-col>

                                <v-col
                                  cols="12"
                                  sm="6"
                                  md="4"
                                  style="margin-top: -20px"
                                >
                                  <label
                                    style="
                                      font-variant: all-small-caps;
                                      font-size: 85%;
                                    "
                                    >Valor total</label
                                  >
                                  <money
                                    v-model="editedItem.ZF3_VTOTAL"
                                    dense
                                    v-bind="money"
                                    :readonly="isReading"
                                    class="
                                      v-input
                                      text-uppercase
                                      v-input--dense
                                      theme--light
                                      v-text-field v-text-field--is-booted
                                    "
                                  ></money>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <v-select
                                    v-model="editedItem.ZF3_LADORM"
                                    :placeholder="'Lado'"
                                    :items="itemsLadoRM"
                                    :label="'Lado'"
                                    class="text-uppercase"
                                    dense
                                    :readonly="isReading"
                                    @change="handleQuantidade"
                                  ></v-select>
                                </v-col>
                              </v-row>

                              <v-row>
                                <v-col cols="12" sm="6" md="4">
                                  <v-autocomplete
                                    v-model="editedItem.ZF3_TPMOLD"
                                    :placeholder="'Tipo de molde'"
                                    style="margin-top: -22px"
                                    dense
                                    :items="itemsTipoMolde"
                                    @focus="
                                      carregaItemsApont({
                                        ZZP_CAMPO: 'ZF3_TPMOLD',
                                        ZZP_F3TAB: 'ZFE',
                                        ZZP_F3CAMP: 'ZFE_COD;ZFE_DESCRI',
                                      })
                                    "
                                    filled
                                    :label="'Tipo de molde'"
                                    background-color="white"
                                    class="text-uppercase"
                                    :readonly="isReading"
                                  ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <v-autocomplete
                                    v-model="editedItem.ZF3_VENT"
                                    :placeholder="'Tipo de ventilação'"
                                    style="margin-top: -22px"
                                    dense
                                    :items="itemsTipoVent"
                                    @focus="
                                      carregaItemsApont({
                                        ZZP_CAMPO: 'ZF3_VENT',
                                        ZZP_F3TAB: 'ZFF',
                                        ZZP_F3CAMP: 'ZFF_COD;ZFF_DESCRI',
                                      })
                                    "
                                    filled
                                    :label="'Tipo de ventilação'"
                                    background-color="white"
                                    class="text-uppercase"
                                    :readonly="isReading"
                                  ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                  <v-select
                                    v-model="editedItem.ZF3_NYLON"
                                    :placeholder="'Fio de Nylon'"
                                    :items="itemsNylon"
                                    :label="'Fio de Nylon'"
                                    class="text-uppercase"
                                    dense
                                    :readonly="isReading"
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveApontamento"
                            >
                              Salvar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="headline"
                            >Tem certeza que deseja remover este
                            item?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                              >Cancelar</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItemConfirm"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-row>

              <v-row dense class="mt-5" v-if="isTracker">
                <v-col>
                  <v-data-table
                    :headers="headersTrackerPrincipal"
                    dense
                    :items="itemsTrackerPrincipal"
                    class="elevation-1"
                    v-if="isTracker"
                    no-data-text="Sem dados..."
                    fixed-header
                    hide-default-footer
                    :loading="loading"
                  >
                  </v-data-table>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
              <v-spacer></v-spacer>

              <v-row dense class="mt-5" v-if="isTracker">
                <v-col>
                  <v-data-table
                    :headers="headersTracker"
                    dense
                    :items="itemsTracker"
                    class="elevation-1"
                    v-if="isTracker"
                    no-data-text="Sem dados..."
                    fixed-header
                    hide-default-footer
                    :loading="loading"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="error"
                text
                @click="
                  DialogDados = false;
                  itemsApontamentos = [];
                  isMolde = false;
                "
                >Fechar</v-btn
              >
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-progress-linear
                  v-model="progress"
                  v-if="progress > 0"
                  color="blue-grey"
                  height="25"
                >
                  <template v-slot="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                v-if="!isReading && !isEditing && !isTracker"
                text
                @click="salva()"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-skeleton-loader
            ref="skeleton"
            :boilerplate="boilerplate"
            type="table"
            class="mx-auto"
            v-if="!boilerplate"
          ></v-skeleton-loader>
        </v-dialog>
      </div>
      <v-bottom-sheet v-model="sheetMessage">
        <v-sheet class="text-center" :color="colorMessage" height="130px">
          <div class="py-3">
            <v-btn class="mx-2" text fab>
              <v-icon
                v-text="iconMessage"
                @click="sheetMessage = false"
              ></v-icon>
            </v-btn>
          </div>
          <div class="py-3" style="font-size: 15px" v-text="message"></div>
        </v-sheet>
      </v-bottom-sheet>

      <v-dialog v-model="dialogSuccessSaveOS" persistent width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2"
            >Ordem de Serviço</v-card-title
          >
          <v-card-text class="mt-5 title">{{ mensagem }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="handleSuccessClose()"
              >fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitSaveOS" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitSaveOS" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2"
            >Ordem de Serviço</v-card-title
          >
          <v-card-subtitle>
            <p class="body-1">Salvando a OS...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitConsulta" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitConsulta" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2"
            >Ordem de Serviço</v-card-title
          >
          <v-card-subtitle>
            <p class="body-1">{{ msgDialogConsulta }}</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitImpressaoAbertura" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitImpressaoAbertura" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2"
            >Impressão de Ordem de Serviço</v-card-title
          >
          <v-card-subtitle>
            <p class="body-1">Imprimindo formulário de abertura...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAwaitBuscaBaseInstalada" persistent width="500">
        <v-card>
          <v-overlay :value="dialogAwaitBuscaBaseInstalada" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-title class="headline grey lighten-2"
            >Ordem de Serviço</v-card-title
          >
          <v-card-subtitle>
            <p class="body-1">Buscando dados na base instalada...</p>
          </v-card-subtitle>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogBaseInstalada" width="1000">
        <v-card>
          <!-- <v-overlay :value="dialogBaseInstalada" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
          <v-card-title class="headline grey lighten-2"
            >Base instalada</v-card-title
          >

          <v-card-text>
            <v-row dense class="mt-5" v-if="isBaseInstalada">
              <v-col>
                <v-data-table
                  v-model="selectedAA3"
                  :headers="headersAA3"
                  :items="itemsAA3"
                  dense
                  class="elevation-1"
                  v-if="isBaseInstalada"
                  no-data-text="Sem dados..."
                  fixed-header
                  hide-default-footer
                  :loading="loading"
                  :single-select="singleSelect"
                  item-key="UNQ_KEY"
                  show-select
                >
                  <!-- <template v-slot:[`item.actions`]="{ item }"> -->
                  <!-- <v-icon small class="mr-2" @click="selecionaItemBaseInstalada(item)">mdi-check</v-icon> -->
                  <!-- <v-btn depressed color="primary" @click="selecionaItemBaseInstalada(item)">Selecionar</v-btn> -->
                  <!-- <v-radio :key="item" :value="item"></v-radio> -->
                  <!-- </template> -->
                </v-data-table>
              </v-col>
            </v-row>

            <v-spacer></v-spacer>

            <v-row>
              <v-col>
                <v-btn
                  depressed
                  color="success"
                  @click="selecionaItemBaseInstalada(selectedAA3[0])"
                  >Confirmar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogErrosOS" width="900">
        <v-card max-width="900" class="mx-auto">
          <v-card-title class="headline red lighten-2" style="color: white"
            >Erros ao salvar</v-card-title
          >

          <v-card-text>
            <v-list disabled>
              <v-subheader>Erros</v-subheader>
              <v-list-item-group v-model="aError" color="primary">
                <v-list-item v-for="(item, i) in aError" :key="i">
                  <v-list-item-icon>
                    <v-icon color="red" v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 0.8rem"
                      v-html="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogErrosOS = false"
              >fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>

    <v-dialog v-model="buscaAgenda" width="90%" persistent>
      <v-card>
        <v-card-title>Agendas</v-card-title>
        <AgendasAbertas
          v-if="buscaAgenda"
          ref="agendasAbertas"
          :cliente="codCliente"
          :tecnico="codTecnico"
        />
        <v-card-actions>
          <v-btn
            class="ml-4 mr-4"
            color="red"
            x-small
            dark
            @click="cancelarAgenda"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="ml-4 mr-4"
            color="green"
            x-small
            dark
            @click="vincularAgenda"
          >
            Vincular
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAwaitVinculaAgenda" persistent width="500">
      <v-card>
        <v-overlay :value="dialogAwaitVinculaAgenda" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-title class="headline grey lighten-2"
          >Ordem de Serviço</v-card-title
        >
        <v-card-subtitle>
          <p class="body-1">Salvando modificações...</p>
        </v-card-subtitle>
      </v-card>
    </v-dialog>

    <!-- PDF -->
    <div id="print-abertura-OS">
      <div class="row text-bold">
        <div class="box-border-1 d-flex col-12">
          <img src="../../assets/opimed-preto-mini.jpg" alt="" height="30" />

          {{ imp_NOMECOM }} - {{ imp_NOME }}<br />
          {{ imp_ENDERECO_FILIAL }}<br />
          Tel.: 0800 100 1989<br />
          {{ imp_EMUSR01 }}
          <v-spacer></v-spacer>
          <div class="box-border-0 d-flex flex-column align-center">
            <span>Nº: {{ imp_NUMOS }}</span>
            <svg id="numos-barcode"></svg>
            <span>Emissão: {{ imp_EMISSAO }} - Status: {{ imp_STATUS }}</span>
          </div>
        </div>
      </div>
      <div class="row text-bold">
        <div class="box-border-1 d-flex col-6">
          Cliente: {{ imp_CLIENTE }}<br />
          Nome: {{ imp_NOME_CLI }}<br />
          Telefone: {{ imp_TELEFONE }}<br />
          Endereço: {{ imp_ENDERECO }}<br />
          Cidade: {{ imp_CIDADE }} - {{ imp_ESTADO }}
        </div>
        <div class="box-border-1 d-flex col-6">
          <div v-if="!isRM">
            Produto: {{ imp_APARELHO }}<br />
            Número de série: {{ imp_NUMSERIE }}<br />
            Data de venda: {{ imp_DATA_VENDA }}<br />
            Garantia até: {{ imp_DT_GARANTIA }}<br />
            Lado: {{ imp_LADO }}
          </div>
        </div>
      </div>

      <div class="row text-bold">
        <div class="box-border-1 d-flex col-6">
          Assessor: {{ imp_ASSESSOR }}
        </div>
        <div class="box-border-1 d-flex col-6">
          {{ imp_TECNICO }}
        </div>
      </div>

      <br />

      <div class="row text-bold">
        <div class="box-border-1 d-flex col-4">Defeito informado</div>
        <div class="box-border-1 d-flex col-4">Acessórios</div>
        <div class="box-border-1 d-flex col-4">Agendado p/:</div>
      </div>

      <div class="row">
        <div class="box-border-1 d-flex col-4">
          {{ imp_OBSERV }}
        </div>
        <div class="box-border-1 d-flex col-4">
          {{ imp_ACESSORIO }}
        </div>
        <div class="box-border-1 d-flex col-4">
          {{ imp_DT_AGENDAMENTO }} - {{ imp_HRINI_AGENDAMENTO }}
        </div>
      </div>

      <div class="row">
        <div class="col-12" v-if="!isRM">
          <h3 class="text-align-center text-bold">
            Termo de Ciência e Concordância
          </h3>

          <p class="text-align-center">
            Declaro que fui devidamente informado(a) pela OPIMED do Brasil Ltda
            que a partir da autorização ou reprovação do orçamento o prazo para
            a retirada do aparelho auditivo é de
            <strong>30 (trinta)</strong> dias e que após este período será
            cobrada taxa adicional no valor de
            <strong>{{ valorMulta }}</strong> ({{ textoMulta }}) mensais
            referente às despesas de armazenagem e seguro. Declaro ainda estar
            ciente que, caso o aparelho auditivo supracitado não seja retirado
            em <strong>até 90 dias</strong> após a ciência do orçamento, ele
            será considerado abandonado e a empresa inutilizará o referido
            aparelho auditivo.
          </p>

          <p class="text-align-center">
            AUTORIZO PREVIAMENTE A LIMPEZA NO VALOR DE
            <strong>{{ valorLimpeza }}</strong> DE CADA APARELHO AUDITIVO:
          </p>

          <p class="text-align-center text-bold">
            <i class="mdi mdi-checkbox-blank-outline"></i> NÃO
            <i class="mdi mdi-checkbox-blank-outline"></i> SIM
          </p>

          <h4 class="text-align-center text-bold">
            SE HOUVER NECESSIDADE DE TROCA DE PEÇAS, ENCAMINHAREMOS O ORÇAMENTO
            PARA AUTORIZAÇÃO
          </h4>

          <br />

          <p class="text-bold">
            Assinatura:
            _______________________________________________________________________________________________________________________________________
          </p>
        </div>

        <div class="col-12" v-if="isRM">
          <div class="table-responsive">
            <table
              class="table table-bordered"
              style="width: 100%; border: 1px solid black"
            >
              <thead>
                <tr>
                  <th>ITEM</th>
                  <th>PRODUTO</th>
                  <th>DESCRIÇÃO</th>
                  <th>QUANTIDADE</th>
                  <th>TIPO</th>
                  <th>VENTILAÇÃO</th>
                  <th>FIO DE NYLON</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in itemsImpressaoRM"
                  :key="`${index}${item.PRODUTO}`"
                >
                  <th scope="row">{{ item.ITEM }}</th>
                  <td>{{ item.PRODUTO }}</td>
                  <td>{{ item.DESCRICAO }}</td>
                  <td>{{ item.QUANTIDADE }}</td>
                  <td>{{ item.TIPORM }} - {{ item.DESC_TIPORM }}</td>
                  <td>{{ item.VENTILACAO }} - {{ item.DESC_VENTILACAO }}</td>
                  <td>{{ item.NYLON }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row" style="margin-bottom: -20px">
        <div class="col-5">Usuário: {{ user.name }}</div>

        <div class="col-7">
          {{ imp_NOMECOM }} ** {{ imp_NOME }} ** Impressão: {{ imp_EMISSAO }}
        </div>
      </div>

      <div class="row mb-2">
        <div
          class="col-12 text-align-center"
          style="border-bottom: 2px dashed black"
        >
          &nbsp;
        </div>
      </div>

      <div class="row text-bold">
        <div class="no-border-bottom-2 d-flex col-12">
          {{ imp_NOMECOM }} - {{ imp_NOME }} Nº: {{ imp_NUMOS }}<br />
          {{ imp_ENDERECO_FILIAL }}<br />
          Tel.: 0800 100 1989<br />
          {{ imp_EMUSR01 }}
        </div>
      </div>

      <div class="row text-bold">
        <div class="only-border-left-2 d-flex col-6">
          Cliente: {{ imp_CLIENTE }}<br />
          Nome: {{ imp_NOME_CLI }}<br />
          Telefone: {{ imp_TELEFONE }}<br />
          Endereço: {{ imp_ENDERECO }}<br />
          Cidade: {{ imp_CIDADE }} - {{ imp_ESTADO }}
        </div>
        <div class="only-border-right-2 d-flex col-6">
          <div v-if="!isRM">
            Produto: {{ imp_APARELHO }}<br />
            Número de série: {{ imp_NUMSERIE }}<br />
            Data de venda: {{ imp_DATA_VENDA }}<br />
            Garantia até: {{ imp_DT_GARANTIA }}<br />
            Lado: {{ imp_LADO }}
          </div>
        </div>
      </div>

      <div class="row text-bold">
        <div class="only-border-left-2 d-flex col-6">
          {{ imp_ASSESSOR }}
        </div>
        <div class="only-border-right-2 d-flex col-6">
          {{ imp_TECNICO }}
        </div>
      </div>

      <div class="row">
        <div class="no-border-top-2 col-12">
          <strong>ATENÇÃO</strong> o prazo para retirada do aparelho auditivo
          após a autorização ou reprovação do orçamento é de
          <strong>30 (trinta)</strong> dias. Após este período será cobrada taxa
          adicional no valor de <strong>{{ valorMulta }}</strong> (noventa e
          cinco reais) mensais referente às despesas de armazenagem e seguro.
          Caso o aparelho auditivo supracitado não seja retirado em
          <strong>até 90 dias</strong> após a ciência do orçamento, ele será
          considerado abandonado e a empresa inutilizará o referido aparelho
          auditivo.
          <br />
          <p class="text-center font-weight-bold">
            O prazo para resposta da análise do aparelho auditivo é de
            {{ prazoOs }}
            contadas a partir do dia agendado.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgendasAbertas from "@/components/OrdemServico/AgendasAbertas";
import { formatDate } from "@/utils/dateParse";
import axios from "axios";
import jsBarcode from "jsbarcode";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { Money } from "v-money";
import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";
import { mapState } from "vuex";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

export default {
  name: "OS1",
  components: { Money, AgendasAbertas },
  data: () => {
    return {
      dialogAwait: false,
      dialogAwait_message: null,
      buscaAgenda: false,
      cont_tabs: 0,
      prazoOs: "", //MV_PRAZOOS
      codigoLimpeza: "", //MV_ZPRLIMP
      valorLimpeza: 0, //B1_PRV1 do produto MV_ZPRLIMP (894)
      valorMulta: 0, //MV_ZVLMULT da SX6
      textoMulta: "noventa e cinco reais",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      valorCliente: "",
      WINDOWS_1252:
        "\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\b\t\n\u000b\f\r\u000e\u000f\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001a\u001b\u001c\u001d\u001e\u001f !\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~€�‚ƒ„…†‡ˆ‰Š‹Œ�Ž��‘’“”•–—˜™š›œ�žŸ ¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ",
      progress: 0,
      drawer: false,
      aError: [],
      dialogErrosOS: false,
      dialogAwaitSaveOS: false,
      dialogAwaitConsulta: false,
      dialogAwaitImpressaoAbertura: false,
      dialogAwaitBuscaBaseInstalada: false,
      dialogAwaitVinculaAgenda: false,
      dialogBaseInstalada: false,
      dialogSuccessSaveOS: false,
      msgDialogConsulta: "",
      errosTE: false,
      mensagem: null,
      sheetMessage: false,
      colorMessage: "",
      iconMessage: "",
      titleDialog: "",
      message: "",
      DialogDados: false,
      isReading: false,
      isTracker: false,
      isMolde: false,
      search: null,
      loading: true,
      altura: 0,
      perPage: 0,
      HeadersOS: [],
      ItemOS: [],
      telaBaseInst: false,
      dialog: false,
      dialogDelete: false,
      headersApontamentos: [],
      itemsApontamentos: [],
      editedIndex: -1,
      editedItem: {
        ZF3_CODSER: "",
        ZF3_CODPRO: "",
        ZF3_QUANT: 0,
        ZF3_VUNIT: 0,
        ZF3_VTOTAL: 0,
        ZF3_LADORM: "",
        ZF3_TPMOLD: "",
        ZF3_VENT: "",
        ZF3_NYLON: "",
      },
      defaultItem: {
        ZF3_CODSER: "",
        ZF3_CODPRO: "",
        ZF3_QUANT: 0,
        ZF3_VUNIT: 0,
        ZF3_VTOTAL: 0,
        ZF3_LADORM: "",
        ZF3_TPMOLD: "",
        ZF3_VENT: "",
        ZF3_NYLON: "",
      },
      itemsLadoRM: [],
      itemsNylon: [],
      itemsServico: [],
      itemsApont: [],
      itemsTipoMolde: [],
      itemsTipoVent: [],

      headersTrackerPrincipal: [],
      itemsTrackerPrincipal: [],
      headersTracker: [],
      itemsTracker: [],
      itemsTipo: [],
      headersAA3: [],
      itemsAA3: [],
      isEditing: false,
      camposTela: {},
      camposApontamentos: {},
      camposTipoMolde: [],
      dadosTela: [],
      cliente: {},
      codCliente: null,
      codTecnico: null,
      codOs: null,
      boilerplate: false,
      dadosCep: [],
      DtInicial: new Date().toISOString().substr(0, 10),
      countObrigatorio: 0,
      cloneDadosTela: [],
      indFiltro: "C",
      awaitMontaBrowser: false,
      filtroEscolhido: null,
      itemsFiltro: ["Filial", "Núm.OS", "Núm. de Série", "Cliente"],
      stringFiltro: null,
      NrRegFiltro: null,
      top500ZF1: [],
      stringCampos: "",
      stringFiliais: "",
      arrayFiliais: [],
      groupBy: "",
      isBaseInstalada: false,
      selectedAA3: [],
      singleSelect: true,
      isRM: false,
      snackbarText: "",
      snackbar: false,
      snackbarTimeout: 3000,
      imp_NUMOS: "",
      imp_NOMECOM: "",
      imp_NOME: "",
      imp_ENDERECO_FILIAL: "",
      imp_TELEFONE_FILIAL: "",
      imp_EMUSR01: "",
      imp_DT_GARANTIA: "",
      imp_DT_AGENDAMENTO: "",
      imp_EMISSAO: "",
      imp_DATA_VENDA: "",
      imp_STATUS: "",
      imp_CLIENTE: "",
      imp_NOME_CLI: "",
      imp_TELEFONE: "",
      imp_ENDERECO: "",
      imp_CIDADE: "",
      imp_ESTADO: "",
      imp_APARELHO: "",
      imp_NUMSERIE: "",
      imp_LADO: "",
      imp_ASSESSOR: "",
      imp_TECNICO: "",
      imp_OBSERV: "",
      imp_ACESSORIO: "",
      imp_HRINI_AGENDAMENTO: "",
      imp_HRFIN_AGENDAMENTO: "",
      HeadersImpressaoRM: [],
      itemsImpressaoRM: [],
    };
  },
  computed: {
    ...mapState([
      "user",
      "metadados",
      "stDrawer",
      "stMini",
      "ordem_servico",
      "tabs",
    ]),
    DataFormatadaInicial() {
      return moment(this.DtInicial).format("DD/MM/YYYY");
    },
    formTitle() {
      return this.editedIndex === -1 ? "Adicionar item" : "Editar item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    async buscaParametros() {
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;
      const dados1 = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "X6_VAR, X6_CONTEUD",
        WHERE: "X6_VAR IN('MV_ZPRLIMP', 'MV_ZVLMULT', 'MV_PRAZOOS')",
        TABELA: "SX6",
      };

      await axios
        .post(url, dados1)
        .then(res => {
          res.data.forEach(e => {
            if (e.X6_VAR === "MV_ZPRLIMP") {
              this.codigoLimpeza = e.X6_CONTEUD.trim() || "894";
            }
            if (e.X6_VAR === "MV_ZVLMULT") {
              this.valorMulta = (
                parseInt(e.X6_CONTEUD?.trim()) ?? 95
              ).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            }
            if (e.X6_VAR === "MV_PRAZOOS") {
              this.prazoOs = e.X6_CONTEUD.trim() || "72 horas";
            }
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      const dados2 = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "B1_PRV1",
        WHERE: `B1_COD = '${this.codigoLimpeza}'`,
        TABELA: "SB1",
      };

      await axios
        .post(url, dados2)
        .then(res => {
          this.valorLimpeza = (res.data?.length > 0
            ? res.data[0].B1_PRV1
            : 155
          ).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      console.log({
        codigo: this.codigoLimpeza,
        valorLimpeza: this.valorLimpeza,
        valorMulta: this.valorMulta,
        prazoOs: this.prazoOs,
      });
      // this.loading = false;
    },

    fromWindows1252(binaryString) {
      var text = "";

      for (var i = 0; i < binaryString.length; i++) {
        text += this.WINDOWS_1252.charAt(binaryString.charCodeAt(i));
      }

      return text;
    },
    vincularAgenda() {
      this.dialogAwaitVinculaAgenda = true;

      const dataAtual = new Date();
      const anoAtual = dataAtual.getFullYear().toString();
      let mesAtual = (dataAtual.getMonth() + 1).toString();
      let diaAtual = dataAtual.getDate().toString();
      if (mesAtual.length < 2) mesAtual = "0" + mesAtual;
      if (diaAtual.length < 2) diaAtual = "0" + diaAtual;

      this.$refs.agendasAbertas.selectedAgenda.forEach(async agenda => {
        const dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          ZZL_STATUS: "A",
          ZZL_ID: agenda.ZZL_ID,
          ZZL_NUMOS: this.codOs,
          ZZL_DTALT: `${anoAtual}${mesAtual}${diaAtual}`,
          ZZL_USALT: this.user.name.substr(0, 20),
          ZZL_TRANSF: "S",
        };

        await axios
          .put(`${process.env.VUE_APP_BASE_API_URL}/agenda`, dados)
          .then(res => {
            if (res.data.STATUS === "true") {
              this.dialogAwaitVinculaAgenda = false;
            }
          })
          .catch(err => console.log(err));
      });
      this.$refs.agendasAbertas.limpaVariaveis();
      this.buscaAgenda = false;
      this.codCliente = null;
      this.codTecnico = null;
      this.codOs = null;
    },
    cancelarAgenda() {
      this.buscaAgenda = false;
    },
    editItem(item) {
      this.editedIndex = this.itemsApontamentos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.itemsApontamentos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.itemsApontamentos.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveApontamento() {
      if (
        this.editedItem.ZF3_QUANT * this.editedItem.ZF3_VUNIT !=
        this.editedItem.ZF3_VTOTAL
      ) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `VALOR TOTAL incorreto!`;
        this.sheetMessage = true;
        return;
      }

      if (this.editedIndex > -1) {
        Object.assign(
          this.itemsApontamentos[this.editedIndex],
          this.editedItem,
        );
      } else {
        this.itemsApontamentos.push(this.editedItem);
      }
      this.close();
    },
    queryAndIndeterminate() {
      this.progress = 0;

      this.interval = setInterval(() => {
        if (this.progress === 100) {
          clearInterval(this.interval);
          this.progress = 0;
          return;
          //return setTimeout(this.queryAndIndeterminate, 2000);
        }
        this.progress += 1;
      }, 1000);
    },
    abrePainel() {
      this.drawer = !this.drawer;
      this.$store.commit("SetStDrawer", this.drawer);
      this.$store.commit("SetStMini", false);
    },
    paineis(chamada) {
      if (chamada === "baseInstalada") this.base();
      if (chamada === "ordemServico") this.ordem();
      // if (chamada === "transfereLead") this.transfereLead();
      // if (chamada === "histLead") this.histLead();
    },
    ordem() {
      this.telaBaseInst = false;
    },
    base() {
      this.telaBaseInst = true;
    },
    print() {
      // Pass the element id here
      this.$htmlToPaper("print-abertura-OS");
    },
    LimpaFiltro() {
      this.stringFiltro = null;
      this.filtroEscolhido = null;
      this.NrRegFiltro = null;
      this.ItemOS = this.top500ZF1;
    },
    async filtraRegistro(campo, stringFiltro) {
      if (!campo || !stringFiltro) return;

      let WHERE = ` ZF1_TIPO IN ('01','02') AND `;

      if (this.user.cod_vendedor.trim() !== "") {
        WHERE += ` ZF1_RESP = '${this.user.cod_vendedor}' AND `;
      }

      if (this.stringFiliais.trim() !== "" && !this.user.isAdminOS) {
        WHERE += `ZF1_MSFIL IN(${this.stringFiliais}) AND `;
      }

      if (campo === "Filial") {
        WHERE += `ZF1_MSFIL LIKE '%${stringFiltro}%'`;
      }

      if (campo === "Núm.OS") {
        WHERE += `ZF1_ID LIKE '%${stringFiltro.toUpperCase()}%'`;
      }

      if (campo === "Núm. de Série") {
        WHERE += `ZF2_NUMSER LIKE '%${stringFiltro}%'`;
      }

      if (campo === "Cliente") {
        WHERE += `(ZF1_CODCLI LIKE '%${stringFiltro.toUpperCase()}%' OR A1_NOME LIKE '%${stringFiltro.toUpperCase()}%')`;
      }

      this.loading = true;
      this.top500ZF1 = this.ItemOS;
      this.ItemOS = [];

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: this.stringCampos,
        WHERE: WHERE,
        GROUP: this.groupBy,
        ORDER: " ZF1_ID",
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/getOS`;

      await axios
        .post(url, dados)
        .then(res => {
          res.data.map(e => {
            e.ZF1_TIPO = `${e.ZF1_TIPO} - ${e.ZF6_DESCRI}`;
            e.ZF1_STATUS = `${e.ZF1_STATUS} - ${e.ZF7_DESCRI}`;
            e.ZF1_CODCLI = `${e.ZF1_CODCLI} - ${e.A1_NOME}`;

            if (e.N_RESP !== null) {
              e.ZF1_RESP = `${e.ZF1_RESP} - ${e.N_RESP}`;
            }

            if (e.N_AS1 !== null) {
              e.ZF1_ASSESS = `${e.ZF1_ASSESS} - ${e.N_AS1}`;
            }

            if (e.N_AS2 !== null) {
              e.ZF1_ASSES2 = `${e.ZF1_ASSES2} - ${e.N_AS2}`;
            }

            if (e.ZZK_NOME !== null) {
              e.ZF1_CODTEC = `${e.ZF1_CODTEC} - ${e.ZZK_NOME.trim()}`;
            }

            e.ZF2_CODPRO = `${e.ZF2_CODPRO} - ${e.B1_DESC || " "}`;
            e.ZF2_DTVEND = `${e.ZF2_DTVEND.substr(6, 2)}/${e.ZF2_DTVEND.substr(
              4,
              2,
            )}/${e.ZF2_DTVEND.substr(0, 4)}`;
            e.ZF2_DATAGR = `${e.ZF2_DATAGR.substr(6, 2)}/${e.ZF2_DATAGR.substr(
              4,
              2,
            )}/${e.ZF2_DATAGR.substr(0, 4)}`;
            e.ZF1_VLRCLI = `${e.ZF1_VLRCLI.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}`;
            e.ZF1_VLRTOT = `${e.ZF1_VLRTOT.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}`;
          });
          this.ItemOS = res.data;
          this.NrRegFiltro = this.ItemOS.length;
        })
        .catch(err => console.log(err));

      this.loading = false;
    },
    obrigat(value, obrigatorio) {
      if (!value && obrigatorio === "1") {
        return ["Campo obrigatório!"];
      }
    },
    //Função para remover caracteres especiais na Notas
    removeAcento(text) {
      text = text.toLowerCase();
      text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
      text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
      text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
      text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
      text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
      text = text.replace(new RegExp("[Ç]", "gi"), "c");
      return text;
    },
    // async carregaItens(item, index) {
    //   this.camposTela[index].ITEMS = await this.chamaConsulta(item);
    // },
    async imprimirFormAbertura(item) {
      let META;
      let campos = "";
      let splitCampo;
      let splitGroupBy;
      let groupBy = "";
      let result;

      this.dialogAwaitImpressaoAbertura = true;

      META = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD010");

      META.forEach(e => {
        if (e.ZZP_BROWSE.trim() !== "0" || e.ZZP_TIPO.trim() === "Y") {
          splitCampo = e.ZZP_CAMPO.trim().split(" ");

          if (e.ZZP_TIPO.trim() === "Y") {
            campos += `ISNULL(CONVERT(VARCHAR(2000), ${splitCampo[0]}), ' ') ${splitCampo[1]},`;
          } else {
            campos += `ISNULL(${splitCampo[0]}, ' ') ${splitCampo[1]},`;
          }

          splitGroupBy = e.ZZP_CAMPO.trim().split(" ");
          groupBy += `${splitGroupBy[0]},`;
        }
      });

      if (campos.substr(-1) === `,`) {
        campos = campos.slice(0, -1);
      }

      if (groupBy.substr(-1) === `,`) {
        groupBy = groupBy.slice(0, -1);
      }

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: campos,
        WHERE: ` ZF1_ID = '${item.ZF1_ID}' `,
        GROUP: groupBy,
        ORDER: " ITEM ",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/getAberturaOS`;

      await axios
        .post(url, dados)
        .then(res => {
          result = res.data[0];
          this.isRM = result.TIPO.trim() === "02";
          this.itemsImpressaoRM = res.data.map(item => ({
            ITEM: item.ITEM.trim(),
            PRODUTO: item.PRODUTO.trim(),
            DESCRICAO: item.DESCRICAO.trim(),
            QUANTIDADE: item.QUANTIDADE,
            TIPORM: item.TIPORM.trim(),
            DESC_TIPORM: item.DESC_TIPORM.trim(),
            VENT: item.VENTILACAO.trim(),
            DESC_VENTILACAO: item.DESC_VENTILACAO.trim(),
            NYLON: item.NYLON.trim() === "S" ? "Sim" : "Não",
          }));

          const ddd1 = result.DDD.trim();
          const tel1 = `${result.TELEFONE.trim().substring(
            0,
            4,
          )}-${result.TELEFONE.trim().substring(4, 8)}`;

          const tel2 = `${result.TEL2.trim().substring(
            0,
            4,
          )}-${result.TEL2.trim().substring(4, 8)}`;
          const ddd2 = tel2 === "-" ? null : ddd1;

          jsBarcode("#numos-barcode", result.NUMOS.trim(), {
            format: "CODE128",
            width: 1.7,
            height: 40,
            displayValue: false,
          });

          this.imp_NUMOS = result.NUMOS.trim();
          this.imp_NOMECOM = result.NOMECOM.trim();
          this.imp_NOME = result.NOME_FILIAL.trim();
          this.imp_ENDERECO_FILIAL = result.ENDERECO_FILIAL.trim();
          this.imp_TELEFONE_FILIAL = result.TELEFONE_FILIAL.trim();
          this.imp_EMUSR01 = result.EMUSR01.trim();
          this.imp_DT_GARANTIA = formatDate(result.DT_GARANTIA);
          this.imp_DT_AGENDAMENTO = formatDate(result.DT_AGENDAMENTO);
          this.imp_EMISSAO = formatDate(result.EMISSAO);
          this.imp_DATA_VENDA = formatDate(result.DATA_VENDA);
          this.imp_STATUS = result.DESCSTATUS.trim();
          this.imp_CLIENTE = result.CLIENTE.trim();
          this.imp_NOME_CLI = result.NOME_CLI.trim();
          this.imp_TELEFONE =
            `(${ddd1}) ${tel1}` + (ddd2 ? `| (${ddd2}) ${tel2}` : "");
          this.imp_ENDERECO = `${result.ENDERECO.trim()} - ${result.BAIRRO.trim()}`;
          this.imp_CIDADE = result.CIDADE.trim();
          this.imp_ESTADO = result.ESTADO.trim();
          this.imp_APARELHO = `${result.APARELHO.trim()} - ${result.APDESC.trim()}`;
          this.imp_NUMSERIE = result.NUMSERIE.trim();
          this.imp_LADO = result.LADO.trim();
          this.imp_ASSESSOR = `${result.ASSESSOR.trim()} - ${result.NOME_ASSESSOR.trim()}`;
          this.imp_TECNICO = `${result.TECNICO.trim()} - ${result.NOME_TECNICO.trim()}`;
          this.imp_OBSERV = result.OBSERV.trim();
          this.imp_ACESSORIO = result.ACESSORIO.trim();
          this.imp_HRINI_AGENDAMENTO = result.HRINI_AGENDAMENTO.trim();
          this.imp_HRFIN_AGENDAMENTO = result.HRFIN_AGENDAMENTO.trim();

          const body = {
            numeroOS: result.NUMOS.trim(),
            nomeEmpresa: result.NOMECOM.trim(),
            lojaEmpresa: result.NOME_FILIAL.trim(),
            ENDERECO_FILIAL: result.ENDERECO_FILIAL.trim(),
            TELEFONE_FILIAL: result.TELEFONE_FILIAL.trim(),
            EMUSR01: result.EMUSR01.trim(),
            dataGarantia: formatDate(result.DT_GARANTIA),
            dataAgendamento: formatDate(result.DT_AGENDAMENTO),
            dataEmissao: formatDate(result.EMISSAO),
            dataVenda: formatDate(result.DATA_VENDA),
            status: result.DESCSTATUS.trim(),
            codigoCliente: result.CLIENTE.trim(),
            nomeCliente: result.NOME_CLI.trim(),
            telefone: `(${ddd1}) ${tel1}` + (ddd2 ? `| (${ddd2}) ${tel2}` : ""),
            endereco: `${result.ENDERECO.trim()} - ${result.BAIRRO.trim()}`,
            cidade: result.CIDADE.trim(),
            estado: result.ESTADO.trim(),
            aparelho: `${result.APARELHO.trim()} - ${result.APDESC.trim()}`,
            nomeroSerie: result.NUMSERIE.trim(),
            lado: result.LADO.trim(),
            assessor: `${result.ASSESSOR.trim()} - ${result.NOME_ASSESSOR.trim()}`,
            tecnico: `${result.TECNICO.trim()} - ${result.NOME_TECNICO.trim()}`,
            observacao: result.OBSERV.trim(),
            acessorio: result.ACESSORIO.trim(),
            horaInicioAgendamento: result.HRINI_AGENDAMENTO.trim(),
            horaFimAgendamento: result.HRFIN_AGENDAMENTO.trim(),
            empresa: "01",
            filial: "0101",
            prazoOs: this.prazoOs,
            valorLimpeza: this.valorLimpeza,
            valorMulta: this.valorMulta,
            textoMulta: "noventa e cinco reais",
            isRM: this.isRM,
            itemsRM: this.itemsImpressaoRM,
          };
          console.log(body);

          this.dialogAwaitImpressaoAbertura = false;
          setTimeout(function() {
            window.print();
          }, 500);
        })
        .catch(err => console.log("Erro: " + err));
    },
    async getPrecoItem() {
      let produto = this.editedItem.ZF3_CODPRO.split("-")[0].trim();
      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `B1_PRV1`,
        WHERE: ` B1_COD = '${produto}' `,
        TABELA: `SB1`,
      };
      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      this.dialogAwaitConsulta = true;
      this.msgDialogConsulta = "Buscando preço do produto...";

      await axios
        .post(url, dados)
        .then(res => {
          this.editedItem.ZF3_VUNIT = res.data[0].B1_PRV1;

          if (this.editedItem.ZF3_QUANT !== 0) {
            this.editedItem.ZF3_VTOTAL =
              this.editedItem.ZF3_QUANT * res.data[0].B1_PRV1;
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.dialogAwaitConsulta = false;
    },
    gatilhoValorTotal() {
      const produto = this.editedItem.ZF3_CODPRO;
      const quantidade = this.editedItem.ZF3_QUANT;
      const valorUnitario = this.editedItem.ZF3_VUNIT;

      if (produto.trim() !== "" || quantidade > 0) {
        this.editedItem.ZF3_VTOTAL = quantidade * valorUnitario;
      }
    },
    handleQuantidade() {
      const lado = this.editedItem.ZF3_LADORM;
      const quantidade = this.editedItem.ZF3_QUANT;

      if (lado === "A=Ambos" && quantidade < 2) {
        this.editedItem.ZF3_QUANT = 2;
        this.gatilhoValorTotal();
      } else if (quantidade < 1) {
        this.editedItem.ZF3_QUANT = 1;
        this.gatilhoValorTotal();
      }
    },
    async selecionaItemBaseInstalada(item) {
      this.dadosTela.ZF1_TIPO = `01-REPARO`;
      this.dadosTela.ZF1_CODCLI = `${item.A1_COD}-${item.A1_NOME}`;
      this.dadosTela.ZF2_CODPRO = `${item.B1_COD}-${item.B1_DESC}`;
      this.dadosTela.ZF2_DTVEND = item.AA3_DTVEND;
      this.dadosTela.ZF2_DATAGR = item.AA3_DTGAR;

      this.dialogBaseInstalada = false;
      this.isBaseInstalada = false;
    },
    async preencheDadosBaseInstalada(item) {
      if (item.ZZP_CAMPO.trim() === "ZF2_NUMSER") {
        let hasNumSerie = false;

        if (typeof this.dadosTela.ZF2_NUMSER !== "undefined") {
          if (this.dadosTela.ZF2_NUMSER.trim() !== "") {
            hasNumSerie = true;
          }
        }

        if (hasNumSerie) {
          let dados = {};
          let stringFields = "";
          let stringTabelas = "";
          let stringWhere = "";

          this.dialogAwaitBuscaBaseInstalada = true;

          stringFields = `A1_COD, A1_LOJA, A1_NOME, AA3_NUMSER, B1_COD, B1_DESC, AA3_DTVEND, AA3_DTGAR, (SELECT COUNT(AA3_NUMSER) FROM AA3010 WHERE D_E_L_E_T_ = ' ' AND AA3_NUMSER = '${this.dadosTela.ZF2_NUMSER}') COUNT`;

          stringWhere = `AA3_NUMSER = '${this.dadosTela.ZF2_NUMSER}' AND `;
          stringWhere += `AA3_CODPRO = B1_COD AND `;
          stringWhere += `AA3_CODCLI = A1_COD AND `;
          stringWhere += `AA3_LOJA = A1_LOJA `;

          stringTabelas += `AA3,SB1,SA1`;

          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: stringFields,
            WHERE: stringWhere,
            TABELA: stringTabelas,
          };

          let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;
          let baseInstaladaDuplicada = false;
          let produto = "";
          let numero_serie = "";
          let AA3 = [];

          await axios
            .post(url, dados)
            .then(res => {
              if (res.data[0].COUNT === 1) {
                const dtVenda = res.data[0].AA3_DTVEND;
                const dtGarantia = res.data[0].AA3_DTGAR;

                this.dadosTela.ZF1_TIPO = `01-REPARO`;
                this.dadosTela.ZF1_CODCLI = `${res.data[0].A1_COD}-${res.data[0].A1_NOME}`;
                this.dadosTela.ZF2_CODPRO = `${res.data[0].B1_COD}-${res.data[0].B1_DESC}`;
                this.dadosTela.ZF2_DTVEND = `${dtVenda.substring(
                  6,
                  8,
                )}/${dtVenda.substring(4, 6)}/${dtVenda.substring(0, 4)}`;
                this.dadosTela.ZF2_DATAGR = `${dtGarantia.substring(
                  6,
                  8,
                )}/${dtGarantia.substring(4, 6)}/${dtGarantia.substring(0, 4)}`;

                produto = res.data[0].B1_COD;
                numero_serie = res.data[0].AA3_NUMSER;
              } else {
                this.loading = true;
                baseInstaladaDuplicada = true;
                produto = res.data[0].B1_COD;
                numero_serie = res.data[0].AA3_NUMSER;
              }
            })
            .catch(error => {
              console.log(error);
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });

          if (baseInstaladaDuplicada) {
            // let ZZP = [];
            this.dialogBaseInstalada = true;
            this.isBaseInstalada = true;

            AA3 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD2_3");
            this.headersAA3 = [];

            let fieldsAA3 = "";

            AA3.map(e => {
              if (e.ZZP_BROWSE === "1") {
                this.headersAA3.push({
                  text: e.ZZP_TITULO.trim(),
                  value: e.ZZP_CAMPO.trim(),
                  align: "left",
                  width: 175,
                  caption: "title",
                });

                fieldsAA3 += `${e.ZZP_CAMPO.trim()},`;
              }
            });

            if (fieldsAA3.substr(-1) === `,`) {
              fieldsAA3 = fieldsAA3.slice(0, -1);
            }

            dados = {
              EMPRESA: "01",
              FILIAL: "0101",
              FIELDS: `${fieldsAA3}, CONCAT(RTRIM(A1_COD), RTRIM(B1_COD), RTRIM(AA3_NUMSER)) UNQ_KEY`,
              WHERE: ` AA3_NUMSER = '${numero_serie}' `,
            };

            url = `${process.env.VUE_APP_BASE_API_URL}/baseInstaladaDuplicada`;

            await axios
              .post(url, dados)
              // .then(res => this.itemsAA3 = res.data)
              .then(res => {
                res.data.forEach(e => {
                  this.loading = false;
                  let dtVend = e.AA3_DTVEND;
                  let dtGarant = e.AA3_DTGAR;

                  e.AA3_DTVEND = `${dtVend.substring(6, 8)}/${dtVend.substring(
                    4,
                    6,
                  )}/${dtVend.substring(0, 4)}`;
                  e.AA3_DTGAR = `${dtGarant.substring(
                    6,
                    8,
                  )}/${dtGarant.substring(4, 6)}/${dtGarant.substring(0, 4)}`;
                });

                this.itemsAA3 = res.data;
              })
              .catch(err => console.log(err));
          }

          dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: ` TOP 1 ZF1_ID `,
            WHERE: ` ZF1_STATUS < '90' AND ZF1_TIPO = '01' AND ZF2_CODPRO = '${produto
              .split("-")[0]
              .trim()}' AND ZF2_NUMSER = '${numero_serie.trim()}' `,
            GROUP: "",
            ORDER: " ZF1_ID",
          };

          url = `${process.env.VUE_APP_BASE_API_URL}/getOSduplicada`;

          await axios
            .post(url, dados)
            .then(res => {
              if (res.data.length > 0) {
                this.dadosTela.ZF1_TIPO = "";
                this.dadosTela.ZF1_CODCLI = "";
                this.dadosTela.ZF2_CODPRO = "";
                this.dadosTela.ZF2_DTVEND = "";
                this.dadosTela.ZF2_DATAGR = "";
                this.dadosTela.ZF2_NUMSER = "";

                this.snackbarText = `Já existe OS aberta para esse número de série. (${res.data[0].ZF1_ID})`;
                this.snackbar = true;
              }
            })
            .catch(err => {
              console.log(err);
            });

          this.dialogAwaitBuscaBaseInstalada = false;
          // this.dialogBaseInstalada = false;
        }
      }
    },
    async mostraApontamentos(item) {
      if (item.ZZP_CAMPO.trim() === "ZF1_TIPO") {
        if (typeof this.dadosTela.ZF1_TIPO !== "undefined") {
          if (this.dadosTela.ZF1_TIPO.substring(0, 2) == "02") {
            this.dadosTela.ZF2_CODPRO = "ZZ_MOLDE PADRAO-";
            this.dadosTela.ZF2_VUNIT = 0;
            this.isMolde = true;
          } else {
            this.itemsApontamentos = [];
            this.dadosTela.ZF2_NUMSER = "";
            this.dadosTela.ZF2_CODPRO = "";
            this.dadosTela.ZF2_VUNIT = 1200;
            this.isMolde = false;
          }
        } else {
          this.isMolde = false;
          this.itemsApontamentos = [];
        }
      }
    },
    async carregaItems(item) {
      this.itemsTipo = [];
      let dados = {};
      let items = [];

      if (item.ZZP_CAMPO.trim() === "ZF1_MSFIL") {
        items = this.arrayFiliais;
        this.itemsTipo = items;
        return;
      } else {
        this.ordem_servico[item.ZZP_F3TAB].forEach(e => {
          const campos = item.ZZP_F3CAMP.split(";");
          dados = `${e[campos[0].trim()].trim()}-${e[campos[1].trim()].trim()}`;
          items.push(dados);
        });

        this.itemsTipo = items;
      }
    },
    async carregaItemsApont(item) {
      let dados = {};
      let items = [];

      this.ordem_servico[item.ZZP_F3TAB].forEach(e => {
        const campos = item.ZZP_F3CAMP.split(";");
        dados = `${e[campos[0].trim()].trim()}-${e[campos[1].trim()].trim()}`;
        items.push(dados);
      });

      if (item.ZZP_CAMPO.trim() === "ZF3_CODSER") {
        this.itemsServico = items;
      }
      if (item.ZZP_CAMPO.trim() === "ZF3_CODPRO") {
        this.itemsApont = items;
      }
      if (item.ZZP_CAMPO.trim() === "ZF3_TPMOLD") {
        this.itemsTipoMolde = items;
      }
      if (item.ZZP_CAMPO.trim() === "ZF3_VENT") {
        this.itemsTipoVent = items;
      }
    },
    async salva() {
      let verificacao = [];
      const camposObrigatorios = this.camposTela.filter(
        campo => campo.ZZP_OBRIGA === "1",
      );

      this.cloneDadosTela = { ...this.dadosTela };

      const keys = Object.keys(this.dadosTela);
      verificacao = camposObrigatorios.map(campo => {
        return keys.indexOf(campo.ZZP_CAMPO.trim()) < 0;
      });

      if (!keys.some(e => e === "ZF2_LADO")) {
        const [tipo] = this.cloneDadosTela["ZF1_TIPO"].split("-");

        if (tipo === "01") {
          verificacao = [...verificacao, true];
        }
        if (tipo === "02" && this.itemsApontamentos.length < 1) {
          verificacao = [...verificacao, true];
        }
      }

      if (verificacao.some(vazio => vazio)) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `CAMPO(S) OBRIGATÓRIO(S) sem preenchimento!`;
        this.sheetMessage = true;
        return;
      }

      if (
        this.cloneDadosTela["ZF1_RESP"].trim() ===
        this.cloneDadosTela["ZF1_ASSESS"].trim()
      ) {
        this.iconMessage = "mdi-close";
        this.colorMessage = "red lighten-4";
        this.message = `MEDICO/INDIC E VENDEDOR/RESPONSÁVEL NÃO PODE TER O MESMO VALOR`;
        this.sheetMessage = true;
        return;
      }

      keys.forEach(e => {
        if (e !== "ZF2_VUNIT" && this.cloneDadosTela[e] !== null) {
          this.cloneDadosTela[e] = this.cloneDadosTela[e].toUpperCase();
        }
      });

      this.queryAndIndeterminate();

      this.camposTela.forEach(e => {
        let value = null;
        if (typeof this.cloneDadosTela[e.ZZP_CAMPO.trim()] !== "undefined") {
          if (e.ZZP_TIPO.trim() === "A") {
            value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("-");

            if (value !== null) {
              this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
            }
          }
          if (e.ZZP_TIPO.trim() === "S") {
            value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("=");
            this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value[0];
          }
          if (e.ZZP_TIPO.trim() === "D") {
            value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].split("/");
            this.cloneDadosTela[
              e.ZZP_CAMPO.trim()
            ] = `${value[2]}${value[1]}${value[0]}`;
          }
          if (
            e.ZZP_TIPO.trim() === "T" &&
            this.cloneDadosTela[e.ZZP_CAMPO.trim()]
          ) {
            value = this.removeAcento(this.cloneDadosTela[e.ZZP_CAMPO.trim()]);
            this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
          }
          if (e.ZZP_TIPO.trim() === "F") {
            value = this.cloneDadosTela[e.ZZP_CAMPO.trim()].substring(0, 4);
            this.cloneDadosTela[e.ZZP_CAMPO.trim()] = value;
          }
        }
      });

      let apontamentosRM = cloneDeep(this.itemsApontamentos);
      apontamentosRM.forEach(item => {
        const [ZF3_CODSER] = item.ZF3_CODSER.split("-");
        const [ZF3_CODPRO, ZF3_DESPRO] = item.ZF3_CODPRO.split("-");
        const [ZF3_TPMOLD] = item.ZF3_TPMOLD.split("-");
        const [ZF3_VENT] = item.ZF3_VENT.split("-");
        const [ZF3_LADORM] = item.ZF3_LADORM.split("=");
        const [ZF3_NYLON] = item.ZF3_NYLON.split("=");
        const { B1_PRV1 } = this.ordem_servico.SB1.find(
          prod => prod.B1_COD.trim() === ZF3_CODPRO.trim(),
        );

        item.ZF3_CODSER = ZF3_CODSER.trim();
        item.ZF3_CODPRO = ZF3_CODPRO.trim();
        item.ZF3_DESPRO = ZF3_DESPRO.trim();
        item.ZF3_TPMOLD = ZF3_TPMOLD.trim();
        item.ZF3_VENT = ZF3_VENT.trim();
        item.ZF3_LADORM = ZF3_LADORM.trim();
        item.ZF3_NYLON = ZF3_NYLON.trim();
        item.ZF3_PRCLIS = B1_PRV1;
        item.ZF3_QUANT = parseFloat(item.ZF3_QUANT);
        item.ZF3_VUNIT = parseFloat(item.ZF3_VUNIT);
        item.ZF3_VTOTAL = parseFloat(item.ZF3_VTOTAL);
        item.ZF3_LOCAL = "AT";
      });

      let os = Object.fromEntries(
        keys.map(key => [key, this.cloneDadosTela[key]]),
      );
      os.COD_USER = this.user.usr_protheus;
      os.ZF1_LOJA = "01";
      os.ZF1_TG = "N";
      os.ZF1_MOEDA = 1;
      os.ZF1_CONDPG = "001";
      // os.ZF1_GERANF = os.ZF1_TIPO.trim() === "01" ? "S" : "N";
      os.ZF1_NUMSER = os.ZF1_TIPO.trim() === "01" ? os.ZF2_NUMSER : "";
      os.ZF1_VLRTOT = 0;
      os.ZF1_ZZABER = "P"; // indica que a abertura foi através do portal
      os.ZF1_ZZFIL = os.ZF1_MSFIL;
      // os.ZF1_TOKEN = '00000000000000015400' // teste forçando duplicidade de token
      os.ZF2_LOCALI = os.ZF1_TIPO.trim() === "01" ? "PADRAO" : "";
      os.ZF2_LOCAL = os.ZF1_TIPO.trim() === "01" ? "TG" : "";
      os.ZF2_VUNIT = parseFloat(os.ZF2_VUNIT);
      os.ZF2_GARANT = "N";
      os.ZF3 = apontamentosRM;

      const dados = {
        EMPRESA: "01",
        FILIAL: os.ZF1_MSFIL,
        OS: os,
      };
      delete dados.OS.ZF1_MSFIL;

      // console.log(dados)

      this.dialogAwaitSaveOS = true;

      const url = `${process.env.VUE_APP_BASE_API_URL}/svOS`;
      await axios
        .post(url, dados)
        .then(res => {
          if (res.data.STATUS === "true") {
            this.idSalvo = res.data.CODIGO;
            // this.mensagem = /*`OS salva com o ID: ${res.data.CODIGO}`*/ `OS salva com sucesso!`;
            this.mensagem = `OS salva com o ID: ${res.data.CODIGO}`;
            this.dialogAwaitSaveOS = false;
            this.dialogSuccessSaveOS = true;
            this.progress = 100;
            this.ItemOS = this.top500ZF1;
            this.itemsApontamentos = [];
            this.montaBrowser();
            this.filtroEscolhido = "Núm.OS";
            this.stringFiltro = res.data.CODIGO;
          } else {
            this.aError = [];

            if (res.data.ERRO.includes("_UNQ")) {
              this.aError.push({
                text: "Não é permitido incluir a mesma OS mais de uma vez!",
                icon: "mdi-send",
              });
            } else {
              const err = res.data.ERRO.split("\n");
              err.forEach(e => {
                this.aError.push({ text: `${e}`, icon: "mdi-send" });
              });
            }

            this.dialogAwaitSaveOS = false;
            this.dialogErrosOS = true;
            this.progress = 100;
            return;
          }
        })
        .catch(err => console.log(err));

      this.camposTela.forEach(e => {
        let value = null;
        if (this.cloneDadosTela["ZF1_TIPO"] !== "02") {
          if (e.ZZP_TIPO.trim() === "D") {
            value = this.cloneDadosTela[e.ZZP_CAMPO.trim()];
            this.cloneDadosTela[e.ZZP_CAMPO.trim()] = `${value.substr(
              6,
              2,
            )}/${value.substr(4, 2)}/${value.substr(0, 4)}`;
          }
        }
      });

      this.progress = 100;
    },
    handleSuccessClose() {
      this.limpaVariaveis();
      this.itemsApontamentos = [];
      this.isMolde = false;
      this.awaitMontaBrowser = true;
    },
    async carregaDados() {
      this.boilerplate = false;
      let campos = [];

      campos = this.metadados.filter(
        e =>
          e.ZZP_MODELO.trim() === "MD2" &&
          (e.ZZP_TABELA.trim() === "ZF1010" ||
            e.ZZP_TABELA.trim() === "ZF2010" ||
            e.ZZP_TABELA.trim() === "ZF3010") &&
          e.ZZP_GRAVA.trim() === "S",
      );

      this.camposTipoMolde = [
        "ZF1_MSFIL",
        "ZF1_ID",
        "ZF1_ZFILAT",
        "ZF1_CODCLI",
        "ZF1_TIPO",
        "ZF2_CODPRO",
        "ZF1_STATUS",
        "ZF1_RESP",
        "ZF1_ZCANAL",
        "ZF1_ASSESS",
        "ZF1_CODTEC",
        "ZF2_VUNIT",
        "ZF2_OBSERV",
        "ZF2_ACESSO",
        "ZF1_LAUDO",
      ];

      await campos.forEach(e => {
        if (e.ZZP_TIPO.trim() === "D") {
          e.MASK = "##/##/####";
        }
        if (e.ZZP_OBRIGA === "1") {
          this.countObrigatorio++;
        }
        if (e.ZZP_CBOX.trim().length > 0) {
          const dados = e.ZZP_CBOX.split(";");
          e.ITEMS = dados.map(i => {
            return `${i}`.trim();
          });
        }
        if (this.camposTipoMolde.includes(e.ZZP_CAMPO.trim())) {
          e.isMolde = true;
        } else {
          e.isMolde = false;
        }
      });

      this.camposTela = campos;
      this.boilerplate = true;
    },
    async reload(item) {
      item.ITEMS = await this.chamaConsulta(item);

      this.camposTela.map(e => {
        if (e.ZZP_CAMPO.trim() === item.ZZP_CAMPO.trim()) {
          e.ITEMS = item.ITEMS;
        }
      });

      return this.camposTela;
    },
    async chamaConsulta(item) {
      let dados = {};
      let items = [];
      const campos = (item.ZZP_F3CAMP || "").trim().split(";");
      let stringCampos = "";

      for (let i = 0; i < campos.length; i++) {
        if (i + 1 >= campos.length) {
          stringCampos += `${campos[i]}`;
        } else {
          stringCampos += `${campos[i]},`;
        }
      }

      if (item.ZZP_F3TAB.trim() === "SX5" && item.ZZP_MODELO.trim() === "MD2") {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${stringCampos.trim()}`,
          WHERE: `X5_TABELA='${item.ZZP_F3TBX5}'`,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      } else {
        dados = {
          EMPRESA: "01",
          FILIAL: "0101",
          FIELDS: `${stringCampos.trim()}`,
          WHERE: ``,
          TABELA: `${item.ZZP_F3TAB}`,
        };
      }

      const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      await axios
        .post(url, dados)
        .then(res => {
          if (item.ZZP_F3TAB) {
            if (item.ZZP_F3TAB.trim() === "AA3") {
              console.log({ campos }); //["AA3_NUMSER"]
            }
          }
          if (campos[0] > 1 && res.data.length > 0) {
            items = res.data.map(e => {
              return `${e[campos[0].trim()].trim()}-`;
            });
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      return items;
    },
    async carregaApontamentos(id) {
      let ZZP = [];
      let ZF3 = [];

      ZF3 = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD2" && e.ZZP_TABELA.trim() === "ZF3010",
      );

      this.loading = true;
      this.headersApontamentos = [];

      ZF3.map(e => {
        if (e.ZZP_TABELA.trim() === "ZF3010") {
          this.headersApontamentos.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "start",
            width: 200,
            caption: "title",
          });
        }
      });

      // let dados = {
      //   EMPRESA: "01",
      //   FILIAL: "0101",
      //   FIELDS: ``,
      //   WHERE: `ZZP_MODELO = 'MD2' AND ZZP_TABELA = 'ZF3010' AND ZZP_USADO = 1 ORDER BY ZZP_ORDEM`,
      //   TABELA: `ZZP`,
      // };

      // let url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      // this.loading = true;
      // this.headersApontamentos = [];

      // await axios
      //   .post(url, dados)
      //   .then((res) => {
      //     res.data.map((e) => {
      //       this.headersApontamentos.push({
      //         text: e.ZZP_TITULO.trim(),
      //         value: e.ZZP_CAMPO.trim(),
      //         align: "start",
      //         width: 200,
      //         caption: "title",
      //       });
      //     });
      //     ZZP = res.data;
      //   })
      // .catch(error => {
      //     console.log(error);
      //     if (error.response.status === 401) {
      //       this.$store.commit("SetUser", null);
      //       return;
      //     }
      //   });

      let stringCampos = "";
      let stringWhere = "";
      let splitCampos;
      let splitCamposArray;
      let arrayTabelas = [];
      let jsonJOIN = {};

      stringWhere += `ZF3_ID = '${id}' `;

      for (let i = 0; i < ZZP.length; i++) {
        if (ZZP[i].ZZP_F3TAB.trim() !== "") {
          splitCampos = `${ZZP[i].ZZP_F3CAMP.trim()}`;
          splitCamposArray = splitCampos.split(";");

          // passo a tabela do join na primeira posição e as condições na segunda posição
          arrayTabelas.push([
            ZZP[i].ZZP_F3TAB.trim(),
            [`${ZZP[i].ZZP_CAMPO.trim()} = ${ZZP[i].ZZP_F3INDI.trim()}`],
          ]);

          for (let i = 0; i < splitCamposArray.length; i++) {
            if (i === 0) {
              stringCampos += `CONCAT(LTRIM(RTRIM(${splitCamposArray[i]}))`;
            } else {
              stringCampos += `,'-',LTRIM(RTRIM(${splitCamposArray[i]}))`;
            }

            if (i + 1 === splitCamposArray.length) {
              stringCampos += `)`;
            }
          }

          stringCampos += ` ${ZZP[i].ZZP_CAMPO.trim()},`;
        } else {
          stringCampos += `${ZZP[i].ZZP_CAMPO.trim()},`;
        }
      }

      if (stringCampos.substr(-1) === `,`) {
        stringCampos = stringCampos.slice(0, -1);
      }

      stringWhere += ` ORDER BY ZF3_SUBITE`;

      jsonJOIN.FIELDS = `${stringCampos}`;
      jsonJOIN.WHERE = `${stringWhere}`;
      jsonJOIN.TABELA_PAI = `ZF3`;
      jsonJOIN.TIPO = `LEFT`;
      jsonJOIN.TABELAS = JSON.parse(JSON.stringify(arrayTabelas));

      let dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        JOIN: jsonJOIN,
      };

      let url = `${process.env.VUE_APP_BASE_API_URL}/apontamentos`;

      this.itemsApontamentos = [];

      await axios
        .post(url, dados)
        .then(res => (this.itemsApontamentos = res.data))
        .catch(err => console.log(err));

      this.perPage = this.itemsApontamentos.length;
      this.loading = false;
    },
    async carregaDadosTracker(id) {
      let MD2_1 = [];
      let MD2_2 = [];
      let dados = {};
      let url = "";
      this.boilerplate = false;

      MD2_1 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD2_1");

      this.loading = true;
      this.headersTrackerPrincipal = [];

      MD2_1.map(e => {
        this.headersTrackerPrincipal.push({
          text: e.ZZP_TITULO.trim(),
          value: e.ZZP_CAMPO.trim(),
          align: "start",
          width: 200,
          caption: "title",
        });
      });

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `FILIAL, CLIENTE, TIPO, STATUS, NF_ENTRADA`,
        WHERE: `1=1 ORDER BY EMISSAO`,
        TABELA: `fn_GetTracker_OS('${id}')`,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      this.itemsTrackerPrincipal = [];

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsTrackerPrincipal.push({
            FILIAL: res.data[0].FILIAL,
            CLIENTE: res.data[0].CLIENTE,
            TIPO: res.data[0].TIPO,
            STATUS: res.data[0].STATUS,
            NF_ENTRADA: res.data[0].NF_ENTRADA,
          });
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      MD2_2 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD2_2");

      this.loading = true;
      this.headersTracker = [];

      MD2_2.map(e => {
        this.headersTracker.push({
          text: e.ZZP_TITULO.trim(),
          value: e.ZZP_CAMPO.trim(),
          align: "start",
          width: 200,
          caption: "title",
        });
      });

      dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `ETAPA, NUMERO, EMISSAO, TIPO_PED, FAT, DATA_FAT, OBSERVACAO, VALOR, USUARIO`,
        WHERE: `1=1 ORDER BY EMISSAO`,
        TABELA: `fn_GetTracker_OS('${id}')`,
      };

      url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

      this.itemsTracker = [];

      await axios
        .post(url, dados)
        .then(res => {
          this.itemsTracker = res.data;
        })
        .catch(error => {
          console.log(error);
          if (error.response.status === 401) {
            this.$store.commit("SetUser", null);
            return;
          }
        });

      this.perPage = this.itemsTracker.length;
      this.loading = false;
      this.boilerplate = true;
    },
    handleVincularAgenda(os) {
      console.log(os);
      (this.codCliente = os.ZF1_CODCLI.substr(0, 6)),
        (this.codTecnico = os.ZF1_CODTEC.substr(0, 6)),
        (this.codOs = os.ZF1_ID);
      this.buscaAgenda = true;
    },
    handleVincularNovaAgenda(os) {
      this.$store.commit("SetVincOsAgenda", { job: true, os });
      this.$store.commit("SetTitle", "Gestão Agenda Auditiva");
      this.$store.commit("SetTabs", {
        name: "Gestão Agenda Auditiva",
        content: "Agenda",
      });
      this.$store.commit("SetTitle", "Gestão Agenda Auditiva");
    },
    verDados(item) {
      this.carregaDados();
      this.carregaApontamentos(item.ZF1_ID);
      this.titleDialog = `Visualizando a Ordem de Serviço: ${item.ZF1_ID}`;
      this.valorCliente = `Valor do cliente: ${item.ZF1_VLRCLI.toLocaleString(
        "pt-BR",
        { style: "currency", currency: "BRL" },
      )}`;
      this.dadosTela = item;
      this.isReading = true;
      this.isTracker = false;
      this.DialogDados = true;

      //<small>Valor do cliente: ${item.ZF1_VLRCLI.toLocaleString("pt-BR", {style: "currency", currency: "BRL"})}</small>
    },
    tracker(item) {
      this.carregaDadosTracker(item.ZF1_ID);
      this.titleDialog = `Tracker da Ordem de Serviço: ${item.ZF1_ID}`;
      this.dadosTela = item;
      this.isReading = false;
      this.isTracker = true;
      this.DialogDados = true;
    },
    novo() {
      const dateNow = new Date();
      const dateISOString = dateNow.toISOString();
      const uniqueHash = dateISOString
        .replaceAll("-", "")
        .replaceAll(":", "")
        .replaceAll(".", "");

      this.progress = 0;
      this.dadosTela = [];
      this.dadosTela["ZF1_STATUS"] = "01-ORCAMENTO";
      this.dadosTela["ZF1_TOKEN"] = (
        "00000000000000000000" + uniqueHash.substring(0, 20)
      ).slice(-20);
      this.dadosTela["ZF2_VUNIT"] = 1200;
      this.isReading = false;
      this.titleDialog = `Nova Ordem de Serviço`;
      this.carregaDados();
      this.DialogDados = true;
      this.isTracker = false;
    },
    limpaVariaveis() {
      this.DialogDados = false;
      this.dialogSuccessSaveOS = false;
      this.montaBrowser();
    },
    async montaBrowser() {
      this.cont_tabs = 0;
      this.altura = window.screen.availHeight - 300;
      this.loading = true;
      this.stringCampos = "";
      this.groupBy = "";

      let MD2 = [];
      let splitGroupBy;

      this.HeadersOS.push({
        text: "Actions",
        value: "actions",
        align: "center",
        width: 150,
        caption: "title",
      });

      /* metadados autocomplete */
      MD2 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD2");
      const autocompletes = MD2.filter(e => e.ZZP_TIPO === "A");

      let tabAux = [
        ...new Set(autocompletes.map(item => item.ZZP_F3TAB.trim())),
      ];

      const tabelasPesquisa = tabAux.map(tabela => {
        const filtroPesquisa = MD2.filter(
          linha => linha.ZZP_F3TAB === tabela,
        ).map(e => ({
          campos: e.ZZP_F3CAMP.trim().replaceAll(";", ","),
          where: e.ZZP_WHERE.trim(),
        }));

        return {
          TABELA: tabela,
          CAMPOS: filtroPesquisa[0].campos,
          WHERE: filtroPesquisa[0].where,
        };
      });

      MD2.map(e => {
        if (e.ZZP_BROWSE.trim() !== "0" || e.ZZP_TIPO.trim() === "Y") {
          /* metadados text field */
          if (e.ZZP_TIPO.trim() === "Y") {
            this.stringCampos += `CONVERT(VARCHAR(2000), ${e.ZZP_CAMPO.trim()}) ${e.ZZP_CAMPO.trim()},`;
          } else {
            this.stringCampos += `${e.ZZP_CAMPO.trim()},`;
          }

          splitGroupBy = e.ZZP_CAMPO.trim().split(" ");
          this.groupBy += `${splitGroupBy[0]},`;
        }

        /* tamanho dos campos visiveis na tabela */
        if (e.ZZP_BROWSE === "1") {
          const name = e.ZZP_CAMPO;
          const width = [
            100,
            100,
            150,
            300,
            350,
            400,
            400,
            500,
            500,
            550,
            600,
            650,
            700,
          ];

          if (name === "ZF1_MSFIL") {
            this.HeadersOS.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "left",
              width: 175,
              caption: "title",
            });
          } else if (name === "ZF1_CODCLI") {
            this.HeadersOS.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "left",
              width: 350,
              caption: "title",
            });
          } else if (name === "ZF2_NUMSER") {
            this.HeadersOS.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "left",
              width: 150,
              caption: "title",
            });
          } else {
            this.HeadersOS.push({
              text: e.ZZP_TITULO.trim(),
              value: e.ZZP_CAMPO.trim(),
              align: "left",
              width: width[e.ZZP_COLS.trim()],
              caption: "title",
            });
          }
        }
      });

      /* popula autocomplete */
      if (!this.ordem_servico.SB1) {
        const total_registros = tabelasPesquisa.length;

        let contador = 0;
        this.dialogAwait = true;
        this.dialogAwait_message = `Aguarde buscando metadados da tela pela primeira vez!`;

        for (const item of tabelasPesquisa) {
          const dados = {
            EMPRESA: "01",
            FILIAL: "0101",
            FIELDS: item.CAMPOS /*FIELDS: `USR_FILIAL, ZM0_NOME`,*/,
            WHERE: item.WHERE,
            TABELA: item.TABELA,
          };

          const url = `${process.env.VUE_APP_BASE_API_URL}/consultas`;

          await axios
            .post(url, dados)
            .then(res => {
              this.$store.commit("setOrdemServico", {
                TABELA: item.TABELA,
                DADOS: res.data,
              });
              contador++;
              this.cont_tabs = (contador * 100) / total_registros;
            })
            .catch(error => {
              console.log(error);
              if (error.response.status === 401) {
                this.$store.commit("SetUser", null);
                return;
              }
            });
        }

        this.dialogAwait = false;

        this.dialogAwait_message = null;
      }

      /* popula autocomplete filiais */
      await axios
        .post(`${process.env.VUE_APP_BASE_API_URL}/os/listarFiliais`, {
          user_protheus: this.user.usr_protheus,
        })
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].USR_FILIAL.trim() !== "") {
              this.stringFiliais += `'${res.data[i].USR_FILIAL.trim()}',`;
              this.arrayFiliais.push(
                `${res.data[i].USR_FILIAL.trim()} - ${res.data[
                  i
                ].ZM0_NOME.trim()}`,
              );
            }
          }

          if (res.data.length === 0) {
            this.arrayFiliais.push("0101 - MATRIZ");
            this.arrayFiliais.push("0102 - PALMAS");
            this.arrayFiliais.push("0103 - MANAUS");
            this.arrayFiliais.push("0104 - ANAPOLIS");
            this.arrayFiliais.push("0105 - RIO VERDE");
            this.arrayFiliais.push("0106 - CATALAO");
            this.arrayFiliais.push("0107 - ITUMBIARA");
            this.arrayFiliais.push("0108 - BELEM");
            this.arrayFiliais.push("0109 - ORION");
            this.arrayFiliais.push("0110 - BRASILIA");
            this.arrayFiliais.push("0111 - ARAGUAINA");
            this.arrayFiliais.push("0112 - SÃO PAULO");
            this.arrayFiliais.push("0113 - BELEM II");
            this.arrayFiliais.push("0114 - BRASILIA II");
            this.arrayFiliais.push("0115 - PORTO VELHO");
            this.arrayFiliais.push("0116 - ÁGUAS CLARAS");
            this.arrayFiliais.push("0117 - MACAPÁ");
          } else {
            res.data.forEach(filial => {
              this.stringFiliais += `'${filial.USR_FILIAL.trim()}',`;
              this.arrayFiliais.push(
                `${filial.USR_FILIAL.trim()} - ${filial.ZM0_NOME.trim()}`,
              );
            });
          }
        })
        .catch(err => console.log(err));

      /* monta consulta de OS */
      let stringWhere = "";

      if (this.stringFiliais.substr(-1) === `,`) {
        this.stringFiliais = this.stringFiliais.slice(0, -1);
      }

      if (this.stringCampos.substr(-1) === `,`) {
        this.stringCampos = this.stringCampos.slice(0, -1);
      }

      if (this.groupBy.substr(-1) === `,`) {
        this.groupBy = this.groupBy.slice(0, -1);
      }

      if (this.stringFiliais.trim() !== "" && !this.user.isAdminOS) {
        stringWhere += `ZF1_MSFIL IN(${this.stringFiliais})`;
      }

      if (this.user.isCliente) {
        stringWhere += ` ZF1_CODCLI = '${this.user.id}' AND ZF1_LOJA = '${this.user.loja}' AND ZF1_TIPO IN ('01','02') AND ZF1_STATUS NOT IN ('90','91','98','99','XX') `;
      } else if (this.user.cod_vendedor.trim() !== "") {
        stringWhere += ` ZF1_RESP = '${this.user.cod_vendedor}' AND ZF1_TIPO IN ('01','02') AND ZF1_STATUS NOT IN ('90','91','98','99','XX') `;
      }

      const dados = {
        FIELDS: `TOP 500 ${this.stringCampos}`,
        WHERE: stringWhere,
        GROUP: this.groupBy,
        ORDER: "ZF1_ID",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/getOS`;

      this.ItemOS = [];

      await axios
        .post(url, dados)
        .then(res => {
          // console.table(res.data)
          res.data.map(e => {
            e.ZF1_TIPO = `${e.ZF1_TIPO} - ${e.ZF6_DESCRI}`;
            e.ZF1_STATUS = `${e.ZF1_STATUS} - ${e.ZF7_DESCRI}`;
            e.ZF1_CODCLI = `${e.ZF1_CODCLI} - ${e.A1_NOME}`;

            if (e.N_RESP !== null && typeof e.N_RESP !== "undefined") {
              e.ZF1_RESP = `${e.ZF1_RESP} - ${e.N_RESP}`;
            }

            if (e.N_AS1 !== null && typeof e.N_AS1 !== "undefined") {
              e.ZF1_ASSESS = `${e.ZF1_ASSESS} - ${e.N_AS1}`;
            }

            if (e.N_AS2 !== null && typeof e.N_AS2 !== "undefined") {
              e.ZF1_ASSES2 = `${e.ZF1_ASSES2} - ${e.N_AS2}`;
            }

            if (e.ZZK_NOME !== null && typeof e.ZZK_NOME !== "undefined") {
              e.ZF1_CODTEC = `${e.ZF1_CODTEC} - ${e.ZZK_NOME}`;
            }

            e.ZF2_CODPRO = `${e.ZF2_CODPRO} - ${e.B1_DESC}`;
            e.ZF2_DTVEND = `${e.ZF2_DTVEND.substr(6, 2)}/${e.ZF2_DTVEND.substr(
              4,
              2,
            )}/${e.ZF2_DTVEND.substr(0, 4)}`;
            e.ZF1_EMISSA = e.ZF1_EMISSA
              ? `${e.ZF1_EMISSA.substr(6, 2)}/${e.ZF1_EMISSA.substr(
                  4,
                  2,
                )}/${e.ZF1_EMISSA.substr(0, 4)}`
              : ``;
            e.ZF2_DATAGR = `${e.ZF2_DATAGR.substr(6, 2)}/${e.ZF2_DATAGR.substr(
              4,
              2,
            )}/${e.ZF2_DATAGR.substr(0, 4)}`;
            e.ZF1_VLRCLI = `${e.ZF1_VLRCLI.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}`;
            e.ZF1_VLRTOT = `${e.ZF1_VLRTOT.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}`;
          });
          this.ItemOS = res.data;
        })
        .catch(err => console.log("Erro: " + err));

      this.perPage = this.ItemOS.length;
      this.loading = false;
      if (this.awaitMontaBrowser) {
        this.filtraRegistro(this.filtroEscolhido, this.stringFiltro);
        this.awaitMontaBrowser = false;
      }
    },
    async initialize() {
      let ZF3 = [];

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });

      ZF3 = this.metadados.filter(
        e => e.ZZP_MODELO.trim() === "MD2" && e.ZZP_TABELA.trim() === "ZF3010",
      );

      this.editedItem = [];

      this.loading = true;
      this.headersApontamentos = [];

      this.headersApontamentos.push({
        text: "Actions",
        value: "actions",
        align: "left",
        width: 75,
        caption: "title",
      });

      await ZF3.map(e => {
        this.headersApontamentos.push({
          text: e.ZZP_TITULO.trim(),
          value: e.ZZP_CAMPO.trim(),
          align: "start",
          width: 200,
          caption: "title",
        });

        this.editedItem[e.ZZP_CAMPO.trim()] = "";

        if (e.ZZP_CAMPO.trim() === "ZF3_LADORM") {
          const dadosLadoRM = e.ZZP_CBOX.split(";");
          this.itemsLadoRM = dadosLadoRM.map(i => {
            return `${i}`;
          });
        }
        if (e.ZZP_CAMPO.trim() === "ZF3_NYLON") {
          const dadosNylon = e.ZZP_CBOX.split(";");
          this.itemsNylon = dadosNylon.map(i => {
            return `${i}`;
          });
        }
      });

      this.camposApontamentos = ZF3;
    },
  },
  created() {
    this.initialize();
  },
  async mounted() {
    await this.buscaParametros();
    this.montaBrowser();
    //this.carregaDados();
    // console.log(this.user.cod_vendedor)
  },
};
</script>

<style>
.v-label {
  font-size: 0.7rem;
}
input {
  font-size: 0.8rem;
}
.text-center,
.text-left {
  font-size: 0.7rem !important;
}
tbody tr:nth-of-type(odd) {
  background-color: #d8f6fa;
}
.v-select__selection {
  font-size: 0.8rem !important;
}
.v-money:focus {
  outline: 0;
}
#print-abertura-OS {
  display: block;
  width: 100%;
}
.box-border-1 {
  border: 1px solid black;
}
.text-align-center {
  text-align: center;
}
.text-bold {
  font-weight: bold;
}
.no-border-top-2 {
  border-top: 1px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}
.no-border-bottom-2 {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-bottom: 1px solid black;
}
.only-border-left-2 {
  border-top: 1px solid black;
  border-left: 2px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}
.only-border-right-2 {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 2px solid black;
  border-bottom: 1px solid black;
}
#print-abertura-OS {
  display: none;
}
@media print {
  body {
    margin: -1mm -5mm;
  }

  #print-abertura-OS {
    display: block;
    font-size: 65%;
    margin-left: -25px;
  }

  .v-navigation-drawer,
  .v-dialog__container,
  .tableCli,
  .v-tabs.theme--light {
    display: none;
  }
}
</style>
