<template>
  <div class="ml-2 mr-2">
    <v-data-table
      class="elevation-1 tbAtividades"
      v-model="selectedAgenda"
      show-select
      dense
      no-data-text="Agenda vazia..."
      fixed-header
      item-key="ZZL_ID"
      :headers="headersAgenda"
      :items="itemsAgenda"
      :height="windowHeight"
      :loading="loadingAgenda"
      :items-per-page="20"
      @click:row="toggleItem"
    >
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-row class="center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                small
                @click="VerDetalhes(item)"
                v-bind="attrs"
                v-on="on"
                :disabled="item.ZW5_STATUS === 'E=ENCERRADO'"
                >mdi-access-point</v-icon
              >
            </template>
            <span>Detalhes</span>
          </v-tooltip>
        </v-row>
      </template> -->

      <template v-slot:[`item.ZZL_STATUS`]="{ item }">
        <v-chip
          class="ma-2"
          v-if="item.ZZL_STATUS === 'A'"
          x-small
          dark
          color="blue darken-1"
        >
          Agendado
        </v-chip>
        <v-chip
          class="ma-2"
          v-if="item.ZZL_STATUS === 'C'"
          x-small
          dark
          color="green"
        >
          Compareceu
        </v-chip>
        <v-chip
          class="ma-2"
          v-if="item.ZZL_STATUS === 'S'"
          x-small
          dark
          color="grey darken-1"
        >
          Bloqueado
        </v-chip>
        <v-chip
          class="ma-2"
          v-if="item.ZZL_STATUS === 'N'"
          x-small
          dark
          color="red darken-1"
        >
          Não Compareceu
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { stringToBrDate } from "../../utils/stringParse";
import { stringToBrClock } from "../../utils/stringParse";

export default {
  name: "AgendasAbertas",
  data: () => ({
    loadingAgenda: false,
    windowHeight: 0,
    selectedAgenda: [],
    headersAgenda: [],
    itemsAgenda: [],
  }),
  props: {
    cliente: {
      type: String,
      required: true,
    },
    tecnico: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["metadados"]),
  },
  methods: {
    toggleItem(item) {
      const selected = this.selectedAgenda.some(e => e.ZZL_ID === item.ZZL_ID);

      if (selected) {
        this.selectedAgenda = this.selectedAgenda.filter(
          e => e.ZZL_ID !== item.ZZL_ID,
        );
      } else {
        this.selectedAgenda.push(item);
      }
    },

    limpaVariaveis() {
      this.selectedAgenda = [];
      this.itemsAgenda = [];
    },
    async montaBrowser() {
      const fields = this.metadados
        .filter(e => e.ZZP_MODELO.trim() === "MD025")
        .map(e => e.ZZP_CAMPO.trim());

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: fields.join(","),
        TABELA: "ZZL010",
        WHERE: `ZZL_CLIENT='${this.cliente}' AND ZZL_PESSOA='${this.tecnico}'`,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/agenda`;

      this.ItemOS = [];

      await axios
        .post(url, dados)
        .then(res => {
          // console.table(res.data)
          res.data.map(e => {
            e.ZZL_TITULO = e.ZZL_TITULO.toUpperCase();
            e.ZZL_DTINI = stringToBrDate(e.ZZL_DTINI);
            e.ZZL_DTFIN = stringToBrDate(e.ZZL_DTFIN);
            e.ZZL_HRINI = stringToBrClock(e.ZZL_HRINI);
          });
          this.itemsAgenda = res.data;
        })
        .catch(err => console.log("Erro: " + err));

      this.loadingAgenda = false;
    },
  },
  async mounted() {
    this.montaBrowser();
  },
  // watch: {
  //   cliente: () => { this.montaBrowser() },
  //   tecnico: () => { this.montaBrowser() },
  // },
  created() {
    this.loadingAgenda = true;
    this.windowHeight = window.screen.availHeight - 400;

    this.headersAgenda = [];
    // this.headersAgenda.push({
    //   text: "Ações",
    //   value: "actions",
    //   align: "start",
    //   width: 80,
    //   caption: "title",
    // });

    const MD025 = this.metadados.filter(e => e.ZZP_MODELO.trim() === "MD025");

    MD025.forEach(e => {
      /* tamanho dos campos visiveis na tabela */
      if (e.ZZP_BROWSE === "1") {
        const name = e.ZZP_CAMPO.trim();
        const width = [
          50, 80, 100, 200, 300, 400, 400, 450, 500, 550, 600, 650, 700,
        ];

        if (name === "A1_NOME") {
          this.headersAgenda.push({
            text: e.ZZP_TITULO.trim(),
            // value: e.ZZP_CAMPO.trim(),
            value: "A1_NOME",
            align: "left",
            width: 250,
            caption: "title",
          });
        } else if (name === "ZZL_TITULO") {
          this.headersAgenda.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "left",
            width: 120,
            caption: "title",
          });
        } else {
          this.headersAgenda.push({
            text: e.ZZP_TITULO.trim(),
            value: e.ZZP_CAMPO.trim(),
            align: "left",
            width: width[e.ZZP_COLS.trim()],
            caption: "title",
          });
        }
      }
    });
  },
  destroyed() {},
};
</script>

<style></style>
